import { setAuthStatus, checkAuthStatus } from './auth';
import loading from './loading';
import {setProfile, getProfile} from './profile'
import {setUser, getUser} from './user'

export const actions = {
    setAuthStatus,
    checkAuthStatus,
    loading,
    setProfile,
    getProfile,
    getUser,
    setUser
};