import React from 'react';
import './Toaster.scss';
import { ToastContainer } from 'react-toastify';

const Toaster = () => {

    return (
        <ToastContainer/>
    )
}

export default Toaster;