
export const setUser = (userData) => {
    return {
        type: 'SET_USER',
        payload: userData
    }
}

export const getUser = () => {
    return {
        type: 'GET_USER',
    }
}