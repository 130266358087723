import { toast } from "react-toastify";

class CommonService {
  // eslint-disable-next-line no-useless-escape
  validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  isEmailValid(email) {
    return this.validEmailRegex.test(email);
  }

  showToast(message, type) {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "warn":
        toast.warn(message);
        break;
      default:
        toast.info(message);
        break;
    }
  }

  checkFormValidity(errors) {
    for (let key in errors) {
      if (errors[key]) {
        return false;
      }
    }
    return true;
  }

  formatCurrency(price) {
    let formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "PKR",
    });

    return formatter.format(price);
  }

  getPriceIntoWords(number, decimals, recursiveCall) {
    const decimalPoints = decimals || 4;
    const noOfLakhs = number / 100000;
    let displayStr = "";
    let isPlural;
    let splitStr = "";
    // Rounds off digits to decimalPoints decimal places
    if (number) {
      if (noOfLakhs >= 1 && noOfLakhs <= 99) {
        const lakhs = this.addZero(this.roundOf(noOfLakhs, decimalPoints));
        isPlural = lakhs > 1 && !recursiveCall;
        displayStr = lakhs;
        splitStr = displayStr.split(".")[1];
        displayStr = splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) : `${lakhs} lac${isPlural ? "s" : ""}`;
      } else if (noOfLakhs >= 100) {
        const crores = this.addZero(this.roundOf(noOfLakhs / 100, decimalPoints));
        const crorePrefix = crores >= 100000 ? this.getPriceIntoWords(crores, decimals, true) : crores;
        isPlural = crores > 1 && !recursiveCall;
        displayStr = crorePrefix;
        splitStr = displayStr.split(".")[1];
        displayStr = splitStr.length > 2 ? this.addZero(this.formatCurrency(Math.round(+number))) : `${crorePrefix} crore${isPlural ? "s" : ""}`;
      } else {
        displayStr = this.addZero(this.formatCurrency(Math.round(+number)));
      }
    }
    displayStr = displayStr.replace("PKR", "").trim();
    return displayStr;
  }

  roundOf = (integer, decimalPoints) => {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  };

  addZero = (price) => {
    let str = price.toString();
    if (!str.split(".")[1]) {
      str = str + ".00";
    } else if (str.split(".")[1] && str.split(".")[1].length < 2) {
      str = str + "0";
    }
    return str;
  };

  getOrderStatus = (status) => {
    if (status === -1) {
      return "Cancelled";
    } else if (status === 0) {
      return "Payment Pending";
    } else if (status === 1) {
      return "Payment Done";
    } else if (status === 2) {
      return "Processing";
    } else if (status === 3) {
      return "Delivered";
    } else if (status === 4) {
      return "Completed";
    } else {
      return status ? status : "Unknown";
    }
  };

  getQueryStatus = (status) => {
    if (status === 0) {
      return "Unread";
    } else if (status === 1) {
      return "Read";
    } else if (status === 2) {
      return "In Progress";
    } else if (status === 3) {
      return "Completed";
    }
  };

  handleError(error) {
    console.log(error.message);
  }
}

export default CommonService;
