// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "react-toastify/dist/ReactToastify.css";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import Interceptor from './config/Interceptor';
import "react-image-gallery/styles/scss/image-gallery.scss";
import './theming/variables.scss';
// import 'react-quill/dist/quill.snow.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import { Provider } from "react-redux";
import configureReduxStore from './store/reduxStore';

const store = configureReduxStore(window.REDUX_INITIAL_DATA);
Interceptor(store);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
