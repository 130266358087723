class LocalStorageService {

    setUserData(Obj, rememberMe) {
        localStorage.setItem('user', JSON.stringify(Obj));
        localStorage.setItem('token', Obj.token);
        localStorage.setItem('refreshToken', Obj.refreshToken);
        localStorage.setItem('rememberMe', rememberMe)
    }

    setTokenOnly(tokenObj) {
        localStorage.setItem('token', tokenObj.token);
    }

    getUser() {
        return localStorage.getItem('user');
    }

    getAccessToken() {
        return localStorage.getItem('token');
    }

    getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    getRememberMe() {
        return localStorage.getItem('rememberMe');
    }

    clearStorage() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('rememberMe');
    }

    setRefreshTokenAndRefreshToken(tokens) {
        localStorage.setItem('token', tokens.token);
        localStorage.setItem('refreshToken', tokens.refreshToken);
    }
}
export default LocalStorageService;

