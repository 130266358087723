import axios from "axios";
import { createHashHistory } from 'history' // or createBrowserHistory
import CommonService from '../services/CommonService';
import UrlInfo from './../config/Configuration'
import LocalStorageService from "../services/LocalStorageService";
import { actions } from '../store/actions/index';

const history = createHashHistory()
const commonSevice = new CommonService();
const urlInfo = new UrlInfo();
const localStorageService = new LocalStorageService();


const Intercepter = (store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    config => {
      store.dispatch(actions.loading({ isLoading: true, percentCompleted: 100 }));
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    error => {
      store.dispatch(actions.loading({ isLoading: false, percentCompleted: 20 }));
      Promise.reject(error)
    });

  //Add a response interceptor
  axios.interceptors.response.use((response) => {
    store.dispatch(actions.loading({ isLoading: false, percentCompleted: 20 }));
    return response
  }, (error) => {
    store.dispatch(actions.loading({ isLoading: false, percentCompleted: 20 }));
    console.log(localStorageService.getRememberMe())
    if (error.response.status === 401 && localStorageService.getRememberMe() === 'false') {
      commonSevice.showToast("Session expired", 'error');
      localStorageService.clearStorage();
      history.push('/login');
      return Promise.reject(error);
    }
    
    if (error.response.status === 401 && localStorageService.getRememberMe() === 'true') {
      const refreshToken = localStorageService.getRefreshToken();
      return axios.post(urlInfo.baseURl + '/users/refreshtoken',
        {
          "refreshToken": refreshToken
        })
        .then(res => {
          if (res.status === 200) {
            const { data } = res;
            if (data && data.message === "Refresh token expired") {
              commonSevice.showToast("Session expired", 'error');
              history.push('/login');
            } else if (data) {
              localStorageService.setTokenOnly(res.data);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
              return axios(error.response.config);
            }
          }
        }).catch(err => {
          commonSevice.showToast("Session expired", 'error');
          history.push('/login');
        })
    }
    return Promise.reject(error);
  });
}

export default Intercepter;
