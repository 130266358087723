import auth from './auth';
import loading from './loading';
import profile from './profile'
import user from './user'

export const reducers = {
    auth,
    loading,
    profile,
    user
};