class Configuration {
  // baseURl = 'https://autodeals.pk';
  baseURl = "https://api.autodeals.pk"; //be2 is for second option if be free hours expired
  // baseURl = "http://localhost:4000";

  reactBootstrapTbloptions = {
    sortIndicator: true,
    // hideSizePerPage: true,
    // paginationSize: 3,
    // hidePageListOnlyOnePage: true,
    clearSearch: true,
    alwaysShowAllBtns: false,
    withFirstAndLast: false,
  };
}
export default Configuration;
