
export const user = (state = null, action) => {
    if (action.type === "SET_USER") {
        state = {...state, userData: action.payload}
        return state;
    } else if(action.type === "GET_USER") {
        return state;
    }
    return state;
};

export default user;